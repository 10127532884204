import React, { createRef, useEffect } from "react";
import * as Colors from "@constants/colors";
import { applyHighlightAnimation } from "@helpers/animations";
import Image from "@components/image";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import { BlockSupTitle } from "@components/shared.styled";
import {
  Container,
  ImageContainer,
  ContentWrapper,
  DescriptionBlock,
} from "@modules/brand-relevance/brand-relevance.styled";
import {
  Highlight,
} from "@modules/potential/potential.styled";
import { Info, LargeHeader, StyledContent, FlexContainer, Button } from "./intro-block.styled";

const IntroBlock = ({
  data: {
    backgroundColor,
    smallHeader,
    largeHeader,
    description,
    buttonLabel,
    highlightColor,
    image,
    budgeUrl,
    budgeAlt,
  },
}) => {
  const highlights = largeHeader.filter(({ isHighlighted }) => isHighlighted);
  const highlightRefs = highlights.map(() => createRef());
  let highlightCount = -1;

  useEffect(() => {
    const tweens = highlightRefs.map(ref => {
      return applyHighlightAnimation(
        ref.current,
        highlightColor || Colors.goldenHourLight
      );
    });

    return () => {
      tweens.forEach(tween => tween.kill());
    };
  });

  return (
    <Container containerBackground={backgroundColor}>
      <StyledContent>
        <ContentWrapper>
          <DescriptionBlock>
            {smallHeader && (
              <BlockSupTitle color={Colors.charcoal}>
                {smallHeader}
              </BlockSupTitle>
            )}
            <LargeHeader>
              {largeHeader.map(({ text, isHighlighted }) => {
                if (isHighlighted === true) {
                  highlightCount += 1;
                  return (
                    <Highlight
                      ref={highlightRefs[highlightCount]}
                      key={text}
                      dangerouslySetInnerHTML={{ __html: text }}
                      highlightColor={highlightColor}
                    />
                  );
                } else {
                  return (
                    <span
                      key={text}
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  );
                }
              })}
            </LargeHeader>
            <Info
              dangerouslySetInnerHTML={{
                __html: convertMarkdownToHtml(description),
              }}
            />
            <FlexContainer>
              <Button to="/careers#openings">{buttonLabel}</Button>
              <Image image={budgeUrl} alt={budgeAlt} width="56px" />
            </FlexContainer>
          </DescriptionBlock>
        </ContentWrapper>
      </StyledContent>
      <ImageContainer>
        <Image width="100%" height="auto" objectFit="cover" image={image[0].imageUrl} alt={image[0].alt} style={{ maxHeight: '480px' }} />
      </ImageContainer>
    </Container>
  );
};

export default IntroBlock;
