import React, { useEffect, createRef } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { ScrollTrigger } from "gsap/all";
import { applyFadeInAnimation } from "@helpers/animations";
import Layout from "@components/layout";
import IntroBlock from "@modules/intro-block";
import TextBlock from "@modules/text-block";
import Carousel from "@components/carousel";
import BenefitsSmall from "@modules/benefits/benefits-small";
import BrandRelevance from "@modules/brand-relevance";
import Openings from "@modules/openings";

const CultureBlock = styled(BenefitsSmall)`
  padding: 70px 0 112px;
`;

const Careers = ({
  data: {
    markdownRemark: {
      frontmatter: {
        pageName,
        metaImage,
        introBlock,
        textBlock,
        culture,
        perksBlock,
        brandRelevance,
        openings,
      },
    },
    positions,
  },
}) => {
  const modules = [
    <IntroBlock data={introBlock} />,
    <TextBlock data={textBlock} />,
    <Carousel />,
    <CultureBlock data={culture} pageType="careers" />,
    <BrandRelevance data={brandRelevance} imageMaxHeight="480px" />,
    <BenefitsSmall data={perksBlock} pageType="careers" />,
    <Openings openings={openings} positions={positions} />,
  ];
  const moduleRefs = modules.map(() => createRef());

  console.log(metaImage.publicURL);

  useEffect(() => {
    moduleRefs.forEach(moduleRef => applyFadeInAnimation(moduleRef));
    return () => ScrollTrigger.getAll().forEach(t => t.kill());
  });

  return (
    <Layout>
      <Helmet>
        <title>{pageName}</title>
        <meta property="og:image" content={`https://www.atwave.com/${metaImage.publicURL}`} />
        <meta property="twitter:image" content={`https://www.atwave.com/${metaImage.publicURL}`}  />
      </Helmet>
      {modules.map((module, index) => (
        <div ref={moduleRefs[index]} key={index}>
          {module}
        </div>
      ))}
    </Layout>
  );
};
export default Careers;

export const query = graphql`
  query CareersPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/careers/" }) {
      frontmatter {
        pageName
        metaImage {
          publicURL
        }
        introBlock {
          smallHeader
          largeHeader {
            text
            isHighlighted
          }
          highlightColor
          description
          buttonLabel
          backgroundColor
          budgeUrl {
            publicURL
          }
          budgeAlt
          image {
            imageUrl {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            alt
          }
        }
        textBlock {
          backgroundColor
          textColor
          smallHeader
          description
        }
        culture {
          backgroundColor
          smallHeader
          resultsList {
            title
            description
          }
        }
        perksBlock {
          largeHeader
          backgroundColor
          textColor
          resultsList {
            alt
            iconBackgroundColor
            stringList {
              item
            }
            imageUrl {
              publicURL
              childImageSharp {
                fixed(width: 69) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        brandRelevance {
          backgroundColor
          smallHeader
          smallHeaderColor
          largeHeader
          description
          imageUrl {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageAlt
        }
        openings {
          backgroundColor
          smallHeader
          largeHeader
          description
          positionButtonLabel
        }
      }
    }
    positions: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/positions/" } }
    ) {
      nodes {
        frontmatter {
          slug
          title
          department
          summary
          buttonLabel
          location
        }
      }
    }
  }
`;
