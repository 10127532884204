import React, { useState, useEffect } from "react";
import CategoriesFilter from "@components/categories-filter";
import {
  Wrapper,
  BlockSupTitle,
  BlockDescription,
} from "@components/shared.styled";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import {
  Container,
  Content,
  InfoBlock,
  StyledBlockTitle,
  PositionsContainer,
  PositionTitle,
  PositionContent,
  PositionDepartment,
  PositionDescription,
  PositionButtonContainer,
  CategoryLabel,
  PositionButton,
  LoadMore,
  LoadMoreLink,
} from "./openings.styled";

const Openings = ({
  openings: {
    backgroundColor,
    smallHeader,
    largeHeader,
    description,
    positionButtonLabel,
  },
  positions,
}) => {
  let positionsArray = [];
  const positionsPerPage = 5;
  const allDepartments = Array.from(
    new Set([
      "All",
      ...positions.nodes.map(({ frontmatter: { department } }) => department),
    ])
  );
  const [positionsToShow, setPositionsToShow] = useState([]);
  const [next, setNext] = useState(3);

  const [activeDepartment, setActiveDepartment] = useState("All");
  const [viewPositions, setViewPositions] = useState(positions.nodes);

  const loopWithSlice = (start, end) => {
    const slicedPosts = viewPositions.slice(start, end);
    positionsArray = [...positionsArray, ...slicedPosts];
    setPositionsToShow(positionsArray);
  };

  useEffect(() => {
    loopWithSlice(0, positionsPerPage);
  }, [viewPositions]);

  useEffect(() => {
  }, [positionsToShow]);

  const handleShowMorePositions = () => {
    loopWithSlice(0, next + positionsPerPage);
    setNext(next + positionsPerPage);
  };

  const clickHandler = departmentName => {
    setActiveDepartment(departmentName);

    if (departmentName !== "All") {
      const newPositions = positions.nodes.filter(
        ({ frontmatter: { department } }) => department === departmentName
      );
      setViewPositions(newPositions);
    } else {
      setViewPositions(positions.nodes);
    }

  };

  return (
    <Container id="openings" backgroundColor={backgroundColor}>
      <Wrapper>
        <Content>
          <InfoBlock>
            {smallHeader && <BlockSupTitle>{smallHeader}</BlockSupTitle>}
            <StyledBlockTitle>{largeHeader}</StyledBlockTitle>
            <BlockDescription
              dangerouslySetInnerHTML={{
                __html: convertMarkdownToHtml(description),
              }}
            />
          </InfoBlock>
          <CategoriesFilter
            categories={allDepartments}
            activeCategory={activeDepartment}
            clickHandler={clickHandler}
          />
          <PositionsContainer>
            {positionsToShow.map(
              ({
                frontmatter: {
                  slug,
                  buttonLabel,
                  title,
                  department,
                  summary,
                  location,
                },
              }) => (
                <PositionContent key={title}>
                  <PositionTitle>{title}</PositionTitle>
                  <PositionDepartment>{location}</PositionDepartment>
                  <PositionDescription
                    dangerouslySetInnerHTML={{
                      __html: convertMarkdownToHtml(summary),
                    }}
                  />
                  <PositionButtonContainer>
                    <CategoryLabel>{department}</CategoryLabel>
                    <PositionButton to={`/careers/${slug}`}>
                      {buttonLabel}
                    </PositionButton>
                  </PositionButtonContainer>
                </PositionContent>
              )
            )}
            {viewPositions.length > positionsToShow.length && (
              <LoadMore>
                <LoadMoreLink role="button" onClick={handleShowMorePositions}>View more +</LoadMoreLink>
              </LoadMore>
            )}
          </PositionsContainer>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default Openings;
