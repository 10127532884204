import React, { useState, useRef } from "react";
import useOnClickOutside from "@helpers/use-on-click-outside";
import ArrowDown from "@svg-icons/arrow-down";
import * as Colors from "@constants/colors";
import { Container, Button, ArrowContainer, DropdownItems, Item } from "./mobile-categories.styled";

const MobileCategories = ({ categories, activeCategory, clickHandler }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => setIsOpen(false));

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container ref={ref} onClick={toggle}>
      <Button type="button">
        {activeCategory}
        <ArrowContainer isArrowRotated={isOpen}>
          <ArrowDown color={Colors.black} />
        </ArrowContainer>
      </Button>
      {isOpen && <DropdownItems >
        {categories.map((category) => (
          <Item
            key={category}
            isActive={activeCategory === category}
            onClick={() => clickHandler(category)}
          >
            {category}
          </Item>
        ))}
      </DropdownItems>}
    </Container>
  );
};

export default MobileCategories;
