import styled from "styled-components";
import * as Colors from "@constants/colors";
import * as Fonts from "@constants/fonts";
import { Screens } from "@constants/screens";
import { Link } from "gatsby";
import { BlockTitle } from "@components/shared.styled";

const Container = styled.section`
  padding: 112px 0 112px;
  background: ${({ backgroundColor = Colors.offWhite }) => backgroundColor};

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
`;

const InfoBlock = styled.div`
  margin: 0 auto;
  max-width: 463px;
  width: 100%;
  text-align: center;
`;

const StyledBlockTitle = styled(BlockTitle)`
  margin: 0 0 26px;
`;

const PositionsContainer = styled.div`
  width: 100%;
  color: ${Colors.charcoal};
  text-align: left;
`;

const PositionContent = styled.div`
  padding: 32px 0;
  font-size: 20px;
  border-bottom: 1px solid #797979;
`;

const PositionTitle = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin: 0;
  font-family: ${Fonts.ClearfaceFont};
`;

const PositionDepartment = styled.p`
  margin: 12px 0 20px;
  font-family: ${Fonts.ClearfaceFont};
  font-size: 16px;
  line-height: 24px;
`;

const PositionDescription = styled.div`
  font-family: ${Fonts.ProximaNovaFont};
  font-size: 16px;
  line-height: 24px;
  max-width: 640px;
`;

const PositionButtonContainer = styled.div`
  margin: 20px 0 0;
  display: flex;
  justify-content: space-between;
`;

const CategoryLabel = styled.div`
  padding: 6px 16px;
  background: ${Colors.light};
  border-radius: 16px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
`;

const PositionButton = styled(Link)`
  padding: 6px 16px;
  background: ${Colors.primary};
  color: ${Colors.white};
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
`;

const LoadMoreLink = styled.button`
  background-color: transparent;
  border: none;
  font-family: ${Fonts.ClearfaceFont};
  font-size: 20px;
  text-decoration: underline;
  cursor: pointer;
`;

export {
  Container,
  Content,
  InfoBlock,
  StyledBlockTitle,
  PositionsContainer,
  PositionTitle,
  PositionContent,
  PositionDepartment,
  PositionDescription,
  PositionButtonContainer,
  CategoryLabel,
  PositionButton,
  LoadMore,
  LoadMoreLink,
};
