import React from 'react';
import Section from '@components/section';
import { Wrapper, BlockSupTitle } from "@components/shared.styled";
import * as Colors from "@constants/colors";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import { Container, Description } from './text-block.styled'

const TextBlock = ({ data: { smallHeader, description, backgroundColor, textColor }}) => (
  <Section backgroundColor={backgroundColor} textColor={textColor}>
    <Wrapper>
      <Container>
        <BlockSupTitle color={Colors.primary}>{smallHeader}</BlockSupTitle>
        <Description
          dangerouslySetInnerHTML={{
            __html: convertMarkdownToHtml(description),
          }}
        />
      </Container>
    </Wrapper>
  </Section>
);

export default TextBlock;
