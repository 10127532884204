import React from "react";
import MobileCategories from "./mobile-categories";
import { CategoriesBar, CategoryButton } from "./categories-filter.styled";

const CategoriesFilter = ({ categories, activeCategory, clickHandler }) => (
  <>
    <CategoriesBar>
      {categories.map((category) => (
        <CategoryButton
          key={category}
          type="button"
          isActive={activeCategory === category}
          onClick={() => clickHandler(category)}
        >
          {category}
        </CategoryButton>
      ))}
    </CategoriesBar>
    <MobileCategories
      categories={categories}
      activeCategory={activeCategory}
      clickHandler={clickHandler}
    />
  </>
);

export default CategoriesFilter;
