import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Slider from "react-slick";
import {
  Wrapper,
  Container,
  Dots,
  SlickContainer,
  SlickImage,
} from "./carousel.styled";

const defaultAppendDots = dot => <Dots>{dot}</Dots>;

const Carousel = () => {
  const {
    markdownRemark: {
      frontmatter: { carousel },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/careers/" }) {
          frontmatter {
            carousel {
              imageUrl {
                publicURL
                childImageSharp {
                  fixed(height: 405, quality: 90) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              alt
            }
          }
        }
      }
    `
  );

  const settings = {
    dots: true,
    appendDots: defaultAppendDots,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    swipe: true,
    swipeToSlide: true,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <Container>
        <Slider {...settings}>
          {carousel?.map(({ imageUrl, alt }) => (
            <SlickContainer key={alt}>
              <SlickImage
                image={imageUrl}
                isFixed
                width="100%"
                height="100%"
                alt={alt}
              />
            </SlickContainer>
          ))}
        </Slider>
      </Container>
    </Wrapper>
  );
};

export default Carousel;
