import styled from "styled-components";
import Image from "@components/image";
import { Screens } from "@constants/screens";

export const Wrapper = styled.div`
  padding: 96px 0 35px;
  overflow-x: hidden;
`;

export const Container = styled.div`
  .slick-track {
    display: flex;
    .slick-slide {
      height: auto;
      div,
      img {
        height: 100%;
      }
    }
  }
  .slick-list {
    overflow: visible;
  }
`;

export const SlickContainer = styled.div`
  margin: 0 16px;
`;

export const SlickImage = styled(Image)`
  img,
  .gatsby-image-wrapper {
    max-width: calc(100vw - 32px);
    @media (max-width: ${Screens.md}) {
      width: 100%;
    }
  }
`;

export const Dots = styled.ul`
  &.slick-dots {
    bottom: -32px;

    li:not(:last-child) {
      margin: 0 12px 0 0;
    }

    li:last-child {
      margin: 0;
    }

    li {
      width: 12px;
      height: 12px;
      button {
        width: 12px;
        height: 12px;
        &:before {
          font-size: 12px;
          line-height: 12px;
          width: 12px;
          height: 12px;
          color: #fdf6ea;
          opacity: 1;
        }
      }

      @media (max-width: ${Screens.xs}) {
        width: 8px;
        height: 8px;
        button {
          width: 8px;
          height: 8px;
          &:before {
            font-size: 8px;
            line-height: 8px;
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }
  &.slick-dots li.slick-active button:before {
    color: #f5b336;
    opacity: 1;
  }
`;
