import styled from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { linkStyles } from "@components/button-link/button-link.styled";
import * as Colors from "@constants/colors";
import * as Fonts from "@constants/fonts";
import { Screens } from "@constants/screens";
import { Content } from "@modules/brand-relevance/brand-relevance.styled";

export const StyledContent = styled(Content)`
  padding: 48px 0;
`;

export const LargeHeader = styled.h1`
  display: inline-block;
  font-family: ${Fonts.ClearfaceFont};
  font-size: 40px;
  line-height: 1.25;
  margin: 0 0 5px;
  max-width: 480px;

  @media (max-width: ${Screens.lg}) {
    font-size: 32px;
    line-height: 1.25;
  }

  @media (max-width: ${Screens.md}) {
    max-width: 400px;
  }

  span {
    padding-right: 10px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 64px;
  align-items: center;
  width: 100%;

  @media (max-width: ${Screens.lg}) {
    justify-content: space-between;
    gap: 20px;
  }

  @media (max-width: ${Screens.md}) {
    margin: 0 auto;
    max-width: 320px;
  }
`;

export const Info = styled.div`
  font-size: 18px;
  line-height: 1.375;
  width: 100%;
  margin: 20px 0 40px;
  max-width: 420px;

  @media (max-width: ${Screens.lg}) {
    max-width: 100%;
    font-size: 18px;
    line-height: 1.375;
    margin: 20px auto 40px;
  }

  @media (max-width: ${Screens.md}) {
    max-width: 420px;
  }
`;

export const Button = styled(AnchorLink)`
  ${linkStyles};
  color: ${Colors.white};
  border-color: ${Colors.charcoal};
  background: ${Colors.charcoal};

  :hover {
    background: ${Colors.blackLighter};
    border-color: ${Colors.blackLighter};
  }

  @media (max-width: ${Screens.lg}) {
    font-size: 16px;
  }
`;
