import styled from "styled-components";
import * as FONTS from "@constants/fonts"

export const Container = styled.div`
  margin: 0 auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Description = styled.div`
  margin: 32px 0px;
  max-width: 608px;
  width: 100%;
  font-size: 27px;
  line-height: 40px;
  text-align: center;
  font-family: ${FONTS.ClearfaceFont};
`;
