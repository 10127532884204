import styled, { css, keyframes } from "styled-components";
import { Screens } from "@constants/screens";
import * as Colors from "@constants/colors";
import * as Fonts from "@constants/fonts";

const fadeInAnimation = keyframes`
    0% {
      transform: translateY(-16px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1.0;
    }
`;

export const Container = styled.div`
  position: relative;
  display: none;

  @media only screen and (max-width: ${Screens.md}) {
    display: block;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: space-between;
  padding: 18px 20px;
  width: 260px;
  background: ${Colors.light};
  border-radius: 8px;
  font-family: ${Fonts.ProximaNovaFont};
  font-size: 18px;
  line-height: 18px;
  outline: none;
  border: none;
  cursor: pointer;
`;

export const ArrowContainer = styled.div`
  transition: all 0.2s;
  ${({ isArrowRotated }) =>
    isArrowRotated ? `transform: rotateX(180deg)` : ""};
  display: inline-block;
`;

export const DropdownItems = styled.div`
  animation: 0.25s ease-out 0s 1 ${fadeInAnimation};
  position: absolute;
  display: flex;
  flex-direction: column;
  margin: 8px 0 0;
  padding: 8px;
  width: 260px;
  background: ${Colors.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
`;

export const Item = styled.div`
  padding: 10px 12px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? css`
          background: ${Colors.primary};
          color: ${Colors.white};
        `
      : css`
          background: ${Colors.white};
          color: ${Colors.black};
        `};
`;
