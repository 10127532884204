import styled, { css } from "styled-components";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";

const CategoriesBar = styled.div`
  display: flex;
  padding: 8px;
  background: ${Colors.light};
  border-radius: 8px;

  @media only screen and (max-width: ${Screens.md}) {
    display: none;
  }
`;

const CategoryButton = styled.button`
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;

  ${({ isActive }) =>
    isActive
      ? css`
          background: ${Colors.primary};
          color: ${Colors.white};
        `
      : css`
          background: ${Colors.light};
          color: ${Colors.black};
        `};
`;

export { CategoriesBar, CategoryButton };
